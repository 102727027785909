import React, {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FabricJSEditor } from 'fabricjs-react';
import { ToolbarWrapper, ToolbarBtn } from './toolbar.style';
import { BgImagePopover } from './bg-image-popover.component';
import { WatermarkPopover } from './watermark-popover.component';
import { ColorPopover } from '../color-popover/color-popover.component';
import {
  BackgroundType,
  CanvasRatio,
  IRightToolbarRef,
  IGradient,
} from '../../interfaces/editor.interface';
import ActionPopover from '../action-popover.component';
import TemplateAddModal from './template-popover/template-add-modal';
import CircleLoader2 from '../circle-loader2';
import { useNotification } from '../../contexts/notification.context';
import { projectDetailsUpdate } from '../../requests/project.requests';
import { IProject } from '../../interfaces/projects.interface';
import { refreshThumbnails } from '../../utils/refreshThumbnails';

const Wrapper = styled.div`
  position: absolute;
  left: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1.25rem;
`;

interface IProps {
  editor: FabricJSEditor;
  canvasRatio: CanvasRatio;
  setCanvasRatio: Dispatch<SetStateAction<CanvasRatio>>;
  handleSavePage: (previewImage?: boolean) => Promise<boolean>;
  refreshProjectCard: VoidFunction;
  thumbnails: string[];
  project: IProject;
  setThumbnails: Dispatch<SetStateAction<string[]>>;
}

export const RightToolbar = forwardRef(function RightToolbar(
  {
    editor,
    canvasRatio,
    setCanvasRatio,
    handleSavePage,
    refreshProjectCard,
    thumbnails,
    project,
    setThumbnails,
  }: IProps,
  ref,
) {
  const bgColorRef = useRef<IRightToolbarRef>();
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const [popoverActive, setPopoverActive] = useState(false);
  const [templateAddModalOpen, setTemplateAddModalOpen] = useState<boolean>(false);
  const [templateLoading, setTemplateLoading] = useState<boolean>(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        setBgType: (bgType: BackgroundType) => {
          bgColorRef?.current?.setBgType(bgType);
        },
        setColor: (color: string) => {
          bgColorRef?.current?.setColor(color);
          handleChangeBgColor(color);
        },
        setGradient: (gradient: IGradient | undefined) => {
          bgColorRef?.current?.setGradient(gradient);
        },
        reloadGradient: () => {
          bgColorRef?.current?.reloadGradient();
        },
      };
    },
    [],
  );

  const handleAddToFavorite = () => {
    // const { canvas } = editor;
    // const objects = canvas.getObjects();
    // if (objects.some((obj) => obj.name?.includes('template'))) {
    //   setNotification({
    //     text: 'Готовый шаблон нельзя сохранить, как свой шаблон. Добавьте его в избранное или создайте новую карточку для сохранения в свои шаблоны.',
    //   });
    //   return;
    // }
    setTemplateLoading(true);
    handleSavePage().then(() => {
      setTemplateAddModalOpen(true);
      setTemplateLoading(false);
    });
  };

  const handleChangeRatio = (ratio: CanvasRatio) => {
    projectDetailsUpdate(project.id, {
      canvasRatio: ratio,
    })
      .then((data) => {
        refreshThumbnails(
          project.projectItems.map((item) => item.details),
          setThumbnails,
          data.details.canvasRatio || 'default',
        );
      })
      .then(() => setCanvasRatio(ratio));
  };

  const handleChangeBgColor = (color: string) => {
    if (color && editor) {
      const { canvas } = editor;
      canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas));
      canvas.renderAll();
    }
  };

  return (
    <Wrapper>
      <ToolbarWrapper>
        <ToolbarBtn
          onClick={handleAddToFavorite}
          data-tooltip-position="right"
          data-tooltip={t('action.saveAsTemplates') as string}
        >
          {templateLoading ? (
            <CircleLoader2 shineColor={'#6620C7'} size={20} />
          ) : (
            <span className="icon-heart" />
          )}
        </ToolbarBtn>
      </ToolbarWrapper>
      <ToolbarWrapper>
        <ColorPopover
          ref={bgColorRef}
          colorType={'background'}
          editor={editor}
          handleChangeColor={handleChangeBgColor}
          refreshProjectCard={refreshProjectCard}
          initialFill={'rgba(255, 255, 255, 1)'}
          typeKey="background-color"
        />
        <BgImagePopover
          editor={editor}
          refreshProjectCard={refreshProjectCard}
          canvasRatio={canvasRatio}
        />
        <WatermarkPopover editor={editor} handleSavePage={handleSavePage} />
        <ActionPopover
          position={'right'}
          setIsActive={setPopoverActive}
          className={popoverActive ? 'active' : ''}
          content={[
            {
              active: canvasRatio === 'default',
              icon: 'rectangle',
              text: '3:4',
              callback: () => handleChangeRatio('default'),
            },
            {
              active: canvasRatio === 'square',
              icon: 'square',
              text: '1:1',
              callback: () => handleChangeRatio('square'),
            },
          ]}
        >
          <ToolbarBtn
            active={popoverActive}
            data-tooltip-position={'right'}
            data-tooltip={popoverActive ? null : (t('action.canvasSize') as string)}
          >
            <span className="icon-scaling" />
          </ToolbarBtn>
        </ActionPopover>
      </ToolbarWrapper>
      {templateAddModalOpen && (
        <TemplateAddModal
          setLoading={setTemplateLoading}
          setTemplateAddModalOpen={setTemplateAddModalOpen}
          canvasRatio={canvasRatio}
          thumbnails={thumbnails}
        />
      )}
    </Wrapper>
  );
});

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FabricJSCanvas, FabricJSEditor, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import FabricTypes, { IEvent } from 'fabric/fabric-impl';
import FontFaceObserver from 'fontfaceobserver';
import Components from './editor.style';
import { CANVAS_SIZE } from '../../constants';
import { useCanvasHistory } from '../../contexts/canvas-history.context';
import { LeftToolbar } from '../../components/toolbar/left-toolbar.component';
import { TopToolbar } from '../../components/toolbar/top-toolbar.component';
import { RightToolbar } from '../../components/toolbar/right-toolbar.component';
import { Pages } from '../../components/toolbar/pages.component';
import { RoundBtn } from '../../components/round-btn.component';
import { IconButton } from '../../components/icon-btn.component';
import { Button } from '../../components/button.component';
import { ObjectToolbar } from '../../components/object-toolbar/object-toolbar.component';
import { CanvasLayout } from '../../components/canvas-layout.component';
import { CanvasContextMenu } from '../../components/canvas-context-menu.component';
import { Text } from '../../components/text.component';
import {
  BackgroundType,
  CanvasRatio,
  IObjectWithId,
  IRightToolbarRef,
  LinearGradientType,
  RadialGradientType,
} from '../../interfaces/editor.interface';
import { IProject, IProjectItem } from '../../interfaces/projects.interface';
import {
  getProject,
  getProjectDetails,
  loadImage,
  renameProject,
  saveProjectItem,
} from '../../requests/project.requests';
import { checkImage, saveImage } from '../../requests/files.requests';
import { useNotification } from '../../contexts/notification.context';
import { useCanvasFocus } from '../../contexts/canvas-focus.context';
import {
  handleSetDoubleClick,
  handleReloadTextBoxes,
  handleReloadShapes,
  setCanvasControlStyle,
  handleParseImage,
  handleScaleImageToCanvas,
} from '../../utils/editor.utils';
import { getActionKey } from '../../utils/shared.utils';
import { EDITOR_PROPERTIES } from '../../constants/editor.constants';
import CircleLoader2 from '../../components/circle-loader2';
import DownloadProject from '../../components/download-project.component';
import { useUserContext } from '../../contexts/user.context';
import SkeletonLoader from '../../components/skeleton-loader.component';
import { resizeImage } from '../../utils/imageResize';
import { useShared } from '../../contexts/shared.context';
import ContactSupport from '../../components/support/support.component';
import { Modal } from '../../components/modal.component';
import { DEBOUNCE_SAVE } from '../../constants/shared.constants';
import useDebounce from '../../hooks/use-debounce.hooks';
import { refreshThumbnails } from '../../utils/refreshThumbnails';

const allowedExtensions = ['image/png', 'image/jpeg'];

export const EditorPage = () => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const {
    currentState,
    canvasStates,
    setCanvasStates,
    offCanvasEvents,
    onCanvasEvents,
    historySaveAction,
    handleUndoRedo,
    handleClearHistory,
  } = useCanvasHistory();
  const { isFocused, setIsFocused, setIsInputFocused } = useCanvasFocus();
  const { popoverActive } = useShared();
  const { setFileDropped, startSaveTimer, stopSaveTimer, secondsSaveTimer, isTiming, setIsTiming } =
    useShared();
  const canvasWrapperRef = useRef<HTMLDivElement | null>(null);
  const topToolbarRef = useRef<{ reloadGrid: () => void }>();
  const rightToolbarRef = useRef<IRightToolbarRef>();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { editor, onReady } = useFabricJSEditor();
  const activeObject: any = editor?.canvas?.getActiveObject();
  const [project, setProject] = useState<IProject>({ id: 0, projectName: '', projectItems: [] });
  const [thumbnails, setThumbnails] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>();
  const [canvasRatio, setCanvasRatio] = useState<CanvasRatio>('default');
  const [showLayout, setShowLayout] = useState<boolean>(false);
  const [showSaveAlert, setShowSaveAlert] = useState<boolean>(false);
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<FabricTypes.Point | null>(null);
  const [didMount, setDidMount] = useState(false);
  const [backIcon, setBackIcon] = useState<string>('');
  const [isDragOver, setIsDragOver] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingPreviewImage, setIsSavingPreviewImage] = useState<boolean>(false);
  const [initials, setInitials] = useState<string>('');
  const [projectLoading, setProjectLoading] = useState<boolean>(false);
  const [loadingThumbnails, setLoadingThumbnails] = useState<boolean>(false);
  const [showFileNotification, setShowFileNotification] = useState<boolean>(false);
  const [isShowGoBackModal, setIsShowGoBackModal] = useState<boolean>(false);
  const [textChangeTrigger, setTextChangeTrigger] = useState(0);
  const [projectDetails, setProjectDetails] = useState<any>({});
  const debouncedTextChange = useDebounce(textChangeTrigger, 500);
  const [options, setOptions] = useState<FabricTypes.IObjectOptions>({});
  const debouncedObjectTop = useDebounce(options.top, 1000);
  const debouncedObjectLeft = useDebounce(options.left, 1000);

  const { userData } = useUserContext();

  let saveAlertTimeout: NodeJS.Timeout | number;

  useEffect(() => {
    setDidMount(true);
    return () => {
      handleClearHistory();
    };
  }, []);

  useEffect(() => {
    if (userData) {
      const { name } = userData;
      const firstName = name?.split(' ')[1];
      const surname = name?.split(' ')[0];

      const initials = `${surname ? surname?.charAt(0) : ''}${
        firstName ? firstName?.charAt(0) : ''
      }`.toUpperCase();
      setInitials(initials);
    }
  }, [userData]);

  useEffect(() => {
    if (didMount) {
      historySaveAction(editor as FabricJSEditor);
    }
  }, [debouncedObjectTop, debouncedObjectLeft]);

  const handleKeyboardEvents = useCallback(
    (e: KeyboardEvent) => {
      const actionKey = getActionKey();
      const step = e.shiftKey ? 40 : 4;
      switch (e.code) {
        case 'KeyZ': {
          if (e[actionKey] && !e.shiftKey && !(!canvasStates?.length || currentState < 1)) {
            handleUndoRedo(editor as FabricJSEditor, 'undo');
          }
          if (e[actionKey] && e.shiftKey && currentState !== canvasStates.length - 1) {
            handleUndoRedo(editor as FabricJSEditor, 'redo');
          }
          break;
        }
        case 'ArrowUp':
        case 'ArrowDown': {
          if (activeObject && isFocused) {
            refreshProjectCard();
            e.preventDefault();
            const top = e.code === 'ArrowUp' ? activeObject.top - step : activeObject.top + step;
            setOptions((prevState) => ({ ...prevState, top }));
            activeObject?.set({ top });
            editor?.canvas.renderAll();
          }
          break;
        }
        case 'ArrowLeft':
        case 'ArrowRight': {
          if (activeObject && isFocused) {
            refreshProjectCard();
            e.preventDefault();
            const left =
              e.code === 'ArrowLeft' ? activeObject.left - step : activeObject.left + step;
            setOptions((prevState) => ({ ...prevState, left }));
            activeObject?.set({ left });
            editor?.canvas.renderAll();
          }
          break;
        }
      }
    },
    [editor, isFocused],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvents, false);
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvents, false);
    };
  }, [handleKeyboardEvents]);

  useEffect(() => {
    const active = Object.values(popoverActive).find((value) => value === true);

    if (active) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  }, [popoverActive]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        editor &&
        canvasWrapperRef.current &&
        !canvasWrapperRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editor, canvasWrapperRef]);

  // auto save
  useEffect(() => {
    if (editor) {
      const { canvas } = editor as FabricJSEditor;
      canvas.off('object:modified');
      canvas.on('object:modified', (e) => {
        refreshProjectCard();
        historySaveAction(editor, e);
        if (e.target) {
          e.target.lockMovementX = false;
          e.target.lockMovementY = false;
        }
      });
    }
  }, [editor]);

  useEffect(() => {
    if (editor) {
      editor.canvas.on('text:changed', function () {
        setTextChangeTrigger((prev) => prev + 1);
      });
    }
    return () => {
      if (editor) {
        editor.canvas.off('text:changed');
      }
    };
  }, [editor]);

  useEffect(() => {
    if (debouncedTextChange) {
      refreshProjectCard();
    }
  }, [debouncedTextChange]);

  // когда нажата кнопка ctrl / command то передвигать объект только по осям X/Y
  useEffect(() => {
    if (activeObject) {
      let lastX = activeObject.left;
      let lastY = activeObject.top;

      activeObject.on('moving', ({ e, transform }: IEvent<MouseEvent>) => {
        if (transform?.target) {
          if (!e.shiftKey) {
            activeObject.lockMovementX = false;
            activeObject.lockMovementY = false;
          }
          const diffX = lastX && transform?.target?.left && transform.target.left - lastX;
          const diffY = lastY && transform.target?.top && transform.target.top - lastY;
          if (e.shiftKey && activeObject && diffX === 0 && diffY !== 0) {
            activeObject.lockMovementX = true;
          }
          if (e.shiftKey && activeObject && diffY === 0 && diffX !== 0) {
            activeObject.lockMovementY = true;
          }
          lastX = transform.target?.left;
          lastY = transform.target?.top;
        }
      });
    }

    return () => {
      if (activeObject) {
        activeObject.lockMovementX = false;
        activeObject.lockMovementY = false;
        activeObject.off('moving');
        editor?.canvas.off('object:moving');
      }
    };
  }, [activeObject]);

  const initCanvas = () => {
    const { canvas } = editor as FabricJSEditor;
    handleSavePage();
    canvas.preserveObjectStacking = true;
    canvas.fireRightClick = true;
    canvas.stopContextMenu = true;
    canvas.on('mouse:down', handleContextMenu);
    const originalControl = fabric.Object.prototype.controls.mtr;
    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: 0,
      y: 0.5,
      offsetX: 0,
      offsetY: 80,
      withConnection: true,
      actionName: 'rotate',
      cursorStyle: 'pointer',
      actionHandler: originalControl.actionHandler,
    });
  };

  useEffect(() => {
    if (editor && !editorLoaded) {
      setEditorLoaded(true);
      initCanvas();
    }
  }, [editor, editorLoaded]);

  useEffect(() => {
    if (projectId) {
      setProject((prevState) => ({ ...prevState, id: +projectId }));
    }
  }, [projectId]);

  const handleGetProject = (callback?: (data: IProject) => void, previewImage?: boolean) => {
    if (previewImage) setIsSavingPreviewImage(true);
    else setProjectLoading(true);
    getProject(project.id)
      .then(async ({ data }) => {
        if (data) {
          setProject(data);
          await loadThumbnails(data.projectItems as IProjectItem[]);
          setActiveIndex(0);
          if (callback) {
            callback(data);
          }
        }
      })
      .catch(() => null)
      .finally(() => {
        setProjectLoading(false);
        setIsSavingPreviewImage(false);
      });
  };

  useEffect(() => {
    if (project.id && editorLoaded) {
      handleGetProject();
    }
    // зачем тут project?.details?.withTemplate? Щас дважды тянется проект
  }, [project.id, editorLoaded]);

  const loadThumbnails = async (projectItems: IProjectItem[]) => {
    setLoadingThumbnails(true);
    const thumbnailsArr = await Promise.all(
      [...projectItems].map(async (item) => {
        return item.modifiedImageId ? await loadImage(item.modifiedImageId, 'NORMAL') : '';
      }),
    ).finally(() => setLoadingThumbnails(false));
    setThumbnails(thumbnailsArr);
  };

  const handleChangeName = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setProject((prevState) => ({ ...prevState, projectName: target?.value || '' }));
  };

  const handleRenameProject = () => {
    renameProject(project.id, project.projectName)
      .then(({ data }) => console.log(data))
      .catch(() => null);
  };

  useEffect(() => {
    if (editor) {
      const { canvas } = editor as FabricJSEditor;
      const { width, height } = CANVAS_SIZE[canvasRatio];
      canvas.setWidth(width);
      canvas.setHeight(height);
    }
  }, [editor, CANVAS_SIZE, canvasRatio]);

  useEffect(() => {
    if (didMount) {
      topToolbarRef.current?.reloadGrid();
      rightToolbarRef.current?.reloadGradient();
      // handleSavePage();
    }
  }, [canvasRatio]);

  const refreshProjectCard = (withoutStart?: boolean) => {
    if (secondsSaveTimer === DEBOUNCE_SAVE && !isTiming) {
      if (!withoutStart) {
        startSaveTimer();
        setIsTiming(true);
      }
    }
    const { canvas } = editor as FabricJSEditor;
    const objects = canvas.getObjects();
    const projectItems = [...project.projectItems];
    if (activeIndex !== undefined && projectItems[activeIndex]) {
      const thumbnailsCopy = [...thumbnails];
      const gridObj = objects.find((obj: FabricTypes.Object) => obj.name === 'grid');
      if (gridObj) {
        canvas.remove(gridObj);
      }
      const watermark = objects.find((obj: FabricTypes.Object) => obj.name === 'watermark');
      if (watermark) {
        canvas.bringToFront(watermark);
      }

      if (!canvas.backgroundColor) {
        canvas.setBackgroundColor('rgba(255, 255, 255, 1)', canvas.renderAll.bind(canvas));
        canvas.renderAll();
      }

      const image = canvas.toDataURL({ format: 'jpeg', quality: 0.5 });
      projectItems[activeIndex].details = canvas.toJSON(EDITOR_PROPERTIES);
      thumbnailsCopy[activeIndex] = image;
      setProject((prevState) => ({ ...prevState, projectItems }));
      setThumbnails(thumbnailsCopy);
      topToolbarRef.current?.reloadGrid();

      return { image, projectItems };
    }
  };

  const handleSavePage = (previewImage?: boolean, withoutStart = true) => {
    return new Promise<boolean>((resolve) => {
      stopSaveTimer();
      const data = refreshProjectCard(withoutStart);
      if (data) {
        const { image, projectItems } = data;
        if (activeIndex !== undefined && projectItems[activeIndex]) {
          fetch(image)
            .then((response) => response.blob())
            .then((blob) => {
              const imageFile = new File([blob], `canvas_${projectItems[activeIndex].id}.jpeg`, {
                type: blob.type,
              });

              if (previewImage) setIsSavingPreviewImage(true);
              else setIsSaving(true);

              saveProjectItem(projectItems[activeIndex], imageFile)
                .then(() => {
                  resolve(true);
                  setShowSaveAlert(true);
                  saveAlertTimeout = setTimeout(() => {
                    setShowSaveAlert(false);
                    clearTimeout(saveAlertTimeout);
                  }, 3000);
                })
                .catch(() => resolve(false))
                .finally(() => {
                  setIsSaving(false);
                  setIsTiming(false);
                });
            });
        }
      }
    });
  };

  const exportAsImage = async () => {
    await handleSavePage();
    const { canvas } = editor as FabricJSEditor;
    const { projectName, projectItems } = project;
    const gridObj = canvas._objects.find((obj: FabricTypes.Object) => obj.name === 'grid');
    if (gridObj) {
      canvas.remove(gridObj);
    }
    const a = document.createElement('a');
    a.href = canvas.toDataURL({
      format: 'png',
    });
    a.download =
      projectName && activeIndex !== undefined
        ? `${projectName}_${projectItems[activeIndex].id}.png`
        : 'canvas.png';
    a.click();
    topToolbarRef.current?.reloadGrid();
  };

  useEffect(() => {
    if (project.id)
      getProjectDetails(project.id).then((data) => {
        setProjectDetails(data);
      });
  }, [canvasRatio, project.id]);

  // load canvas
  useEffect(() => {
    const loadFont = async (fontFamily: string) => {
      const fontObserver = new FontFaceObserver(fontFamily);
      return fontObserver.load();
    };

    const details =
      activeIndex !== undefined &&
      project.projectItems &&
      project.projectItems[activeIndex]?.details;

    function loadCanvas() {
      if (editor && details) {
        const { canvas } = editor as FabricJSEditor;
        canvas.loadFromJSON(details, () => {
          setCanvasStates([JSON.stringify(canvas.toJSON(EDITOR_PROPERTIES))]);
          offCanvasEvents(editor);
          const gridObj = canvas._objects.find((obj: FabricTypes.Object) => obj.name === 'grid');
          if (gridObj) {
            canvas.remove(gridObj);
          }
          setCanvasRatio((projectDetails?.canvasRatio as CanvasRatio) || 'default');
          canvas._objects.forEach((obj) => {
            handleSetDoubleClick(canvas, obj);
            handleReloadTextBoxes(obj);
            handleReloadShapes(canvas, obj);
          });
          refreshThumbnails(
            project.projectItems.map((item) => item.details),
            setThumbnails,
            projectDetails?.canvasRatio || 'default',
          );
          reloadBackground();
          canvas.renderAll();
          topToolbarRef.current?.reloadGrid();
          onCanvasEvents(editor);

          if (project.details?.withTemplate) {
            void handleSavePage(false);
          }
        });
      }
    }

    if (details?.objects) {
      const objectsFonts = details.objects
        .filter((obj: FabricTypes.Object) => obj?.type === 'textbox')
        .map((obj: FabricTypes.Textbox) => obj?.fontFamily);
      objectsFonts.push('Inter');

      const uniqueFonts: Set<string> = new Set(objectsFonts);
      const fontPromises: Promise<void>[] = [];
      const allObservers: Promise<void>[] = [];

      uniqueFonts.forEach((font) => {
        const englishObserver = new FontFaceObserver(font);
        const russianObserver = new FontFaceObserver(font);
        const kazakhObserver = new FontFaceObserver(font);

        allObservers.push(englishObserver.load('Aa', 5000));
        allObservers.push(russianObserver.load('Аа', 5000));
        allObservers.push(kazakhObserver.load('ӘҒҚҢӨҰҮҺ', 5000));

        fontPromises.push(loadFont(font));
      });

      Promise.all([...fontPromises, ...allObservers])
        .then(() => {
          loadCanvas();
        })
        .catch((err) => {
          loadCanvas(); // На всякий случай, если шрифты не загрузятся
          console.log('Шрифты не загрузились: ' + err);
        });
    } else if (editor) {
      loadCanvas();
      offCanvasEvents(editor);
      setCanvasStates([JSON.stringify(editor.canvas.toJSON(EDITOR_PROPERTIES))]);
      onCanvasEvents(editor);
    }
  }, [project.projectItems?.length, activeIndex, projectDetails?.canvasRatio]);

  const reloadBackground = () => {
    const { canvas } = editor as FabricJSEditor;
    const bgGradient = canvas._objects?.find((obj) => obj.name?.includes('backgroundGradient'));
    if (bgGradient) {
      const name: string = bgGradient.name as string;
      const index = name.indexOf('_') + 1;
      const gradientType = name.substring(index, name.length) as
        | LinearGradientType
        | RadialGradientType;
      const gradient: FabricTypes.Gradient = bgGradient.get('fill') as FabricTypes.Gradient;
      rightToolbarRef?.current?.setGradient({ type: gradientType, options: gradient?.toObject() });
      rightToolbarRef?.current?.setBgType(gradient.type as BackgroundType);
      return;
    }

    rightToolbarRef?.current?.setGradient(undefined);
    rightToolbarRef?.current?.setColor(canvas.backgroundColor as string);
    rightToolbarRef?.current?.setBgType('fill');
  };

  const handleNavigateBack = () => {
    setIsShowGoBackModal(false);
    navigate('/my-projects');
  };

  const handleAwaitSave = async () => {
    handleSavePage().then(() => {
      setIsTiming(false);
      location.href = '/my-projects';
      setIsShowGoBackModal(false);
    });
  };

  useEffect(() => {
    if (secondsSaveTimer === 0 && !isSaving && isTiming) {
      if (isShowGoBackModal) {
        handleAwaitSave();
      } else {
        handleSavePage().then(() => {
          setIsTiming(false);
        });
      }
    }
  }, [secondsSaveTimer]);

  const goBackModalContent = (
    <Modal
      width="480px"
      onClose={() => {
        setIsShowGoBackModal(false);
      }}
    >
      <div className="heading">
        Последние изменения еще не были сохранены. <br />
        Хотите сохранить их?
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <Button
          type="button"
          btnStyle="cancel"
          onClick={handleNavigateBack}
          style={{ padding: '0.8125rem 0' }}
        >
          Выйти без сохранения
        </Button>
        <Button type="button" onClick={handleAwaitSave} isLoading={isSaving}>
          Сохранить {secondsSaveTimer !== 0 && `(${secondsSaveTimer})`}
        </Button>
      </div>
    </Modal>
  );

  const handleGoBack = (e: any) => {
    e.preventDefault();
    if (!isTiming) {
      location.href = '/my-projects';
      return;
    }
    setIsShowGoBackModal(true);
  };

  const handlePreview = async () => {
    await handleSavePage(true);
    handleGetProject((projectInfo: IProject) => {
      navigate(`/project/${projectInfo.id}/item`, {
        state: {
          projectInfo,
          image: editor?.canvas.toDataURL({
            format: 'png',
          }),
        },
      });
    }, true);
  };

  useEffect(() => {
    if (activeObject) {
      const { canvas } = editor as FabricJSEditor;
      setCanvasControlStyle(canvas, activeObject);
    }
  }, [activeObject]);

  // Для того, что бы цвет фона текста работал корректно
  useEffect(() => {
    const textbox = activeObject as FabricTypes.Textbox;
    if (textbox?.type === 'textbox') {
      textbox.on('editing:exited', () => {
        const numberOfCharacters = textbox.text ? textbox.text.length : 0;
        textbox.selectionStart = 0;
        textbox.selectionEnd = numberOfCharacters;
      });
    }
  }, [activeObject]);

  // const handleChangeFont = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setFontFamily(event.target.value);
  //   const { canvas } = editor as FabricJSEditor;
  //   if (activeObject) {
  //     activeObject.set('fontFamily', event.target.value);
  //     canvas.renderAll();
  //   }
  // };

  const handleCloseContextMenu = () => {
    setCursorPosition(null);
    setShowContextMenu(false);
  };

  const handleContextMenu = ({ button, e, pointer }: FabricTypes.IEvent<MouseEvent>) => {
    setIsFocused(true);
    // right mouse click
    if (button === 3) {
      const { canvas } = editor as FabricJSEditor;
      const target: any = canvas.findTarget(e, false);
      if (target) {
        canvas.setActiveObject(target);
      } else {
        canvas.discardActiveObject();
        canvas.renderAll();
      }
      e.preventDefault();
      setCursorPosition(pointer as FabricTypes.Point);
      setShowContextMenu(true);
    } else {
      handleCloseContextMenu();
    }
  };

  const handleDropFiles = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const { files } = event.dataTransfer;
    const resizedImagesPromises = [];

    const fileArray = Array.from(files);

    if (files.length > 0) {
      if (fileArray.some((file) => !allowedExtensions.includes(file.type))) {
        setNotification({
          text: t('editor.errors.fileType'),
          errorNotification: true,
        });
        setFileDropped({ value: true, message: t('editor.errors.fileType') as string });
        return;
      }

      for (const file of Array.from(files)) {
        const fileType = file.type;
        if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
          if (file.size <= 5 * 1024 * 1024) {
            resizedImagesPromises.push(resizeImage(file, 1680, 900));
          } else {
            setFileDropped({
              value: true,
              message: `Файл слишком большой (${(file.size / 1048576).toFixed(
                0,
              )} Мб). Максимальный размер: 5 Мб.`,
            });
            return;
          }
        }
      }

      for await (const file of Array.from(files)) {
        const errorList = checkImage(file);
        if (errorList.length > 0) {
          return;
        }

        saveImage([file])
          .then((e) => {
            setFileDropped({ value: e.success, message: '' });
            handleParseImage(file, (image) => {
              const { canvas } = editor as FabricJSEditor;
              handleScaleImageToCanvas(image, 0);
              const id = Math.random().toString(16).slice(2);
              (image as IObjectWithId).set({ id: `${image.type}_${id}`, centeredRotation: true });
              canvas.add(image);
              if (files.length === 1) {
                canvas.setActiveObject(image);
              }
              canvas.renderAll();
              historySaveAction(editor as FabricJSEditor);
            });
          })
          .catch(() => null);
      }
      handleSavePage();
    }
  };

  useEffect(() => {
    if (showFileNotification) {
      setTimeout(() => {
        setShowFileNotification(false);
      }, 3000);
    }
  }, [showFileNotification]);

  return project.id ? (
    <>
      <Components.Header>
        <Components.HeaderItem style={{ gap: '20px', flex: 1 }}>
          <RoundBtn
            as={Link}
            to={'/my-projects'}
            reloadDocument
            data-tooltip={t('myProjects.title') as string}
            onMouseEnter={() => setBackIcon('arrow-left')}
            onMouseLeave={() => setBackIcon('')}
            onClick={handleGoBack}
          >
            {backIcon ? <span className={`icon-${backIcon}`} /> : initials}
          </RoundBtn>
          {!projectLoading ? (
            <Components.NameInput
              placeholder={t('editor.enterName') as string}
              value={project.projectName}
              onChange={handleChangeName}
              onBlur={handleRenameProject}
            />
          ) : (
            <SkeletonLoader height="25px" width="200px" />
          )}
        </Components.HeaderItem>
        <Components.HeaderItemDropDown style={{ gap: '10px' }}>
          {['undo', 'redo'].map((action) => (
            <IconButton
              key={action}
              icon={action}
              title={t(`action.${action}`) as string}
              onClick={() => handleUndoRedo(editor as FabricJSEditor, action)}
              disabled={
                !canvasStates?.length ||
                (action === 'undo' ? currentState < 1 : currentState === canvasStates.length - 1)
              }
            />
          ))}
          <IconButton
            icon={'eye-on'}
            style={{ marginLeft: '10px' }}
            onClick={handlePreview}
            title="Предпросмотр"
            isLoading={isSavingPreviewImage}
          />
          <DownloadProject exportAsImage={exportAsImage} project={project} editor={editor} />
        </Components.HeaderItemDropDown>
      </Components.Header>
      <Components.OutletWrapper>
        <LeftToolbar
          editor={editor as FabricJSEditor}
          canvasRatio={canvasRatio}
          handleSavePage={handleSavePage}
          refreshProjectCard={refreshProjectCard}
          project={project}
          setProject={setProject}
          setActiveIndex={setActiveIndex}
        />
        <ObjectToolbar
          editor={editor as FabricJSEditor}
          activeObject={activeObject}
          handleSavePage={handleSavePage}
          refreshProjectCard={refreshProjectCard}
        />
        <Components.CanvasWrapper id="canvasWrapper">
          <TopToolbar
            ref={topToolbarRef}
            editor={editor as FabricJSEditor}
            setShowLayout={setShowLayout}
          />
          <RightToolbar
            project={project}
            ref={rightToolbarRef}
            editor={editor as FabricJSEditor}
            canvasRatio={canvasRatio}
            setCanvasRatio={setCanvasRatio}
            handleSavePage={handleSavePage}
            refreshProjectCard={refreshProjectCard}
            thumbnails={thumbnails}
            setThumbnails={setThumbnails}
          />

          <Pages
            editor={editor as FabricJSEditor}
            project={project}
            setProject={setProject}
            thumbnails={thumbnails}
            setThumbnails={setThumbnails}
            handleSavePage={handleSavePage}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            canvasRatio={canvasRatio}
            isLoading={projectLoading && loadingThumbnails}
          />

          <Components.DragAndDropZone
            ref={canvasWrapperRef}
            dragOver={isDragOver}
            onDragOver={() => setIsDragOver(true)}
            onDragLeave={() => setIsDragOver(false)}
            onDrop={handleDropFiles}
          >
            {isDragOver && (
              <Components.DragAndDropDescription>
                <h2>{t('editor.dragAndDropImage')}</h2>
                <h5>{t('editor.imageSize')}</h5>
              </Components.DragAndDropDescription>
            )}
            <FabricJSCanvas className="main-canvas" onReady={onReady} />
          </Components.DragAndDropZone>
          <CanvasLayout show={showLayout} canvasRatio={canvasRatio} />
          <CanvasContextMenu
            show={showContextMenu}
            position={cursorPosition}
            editor={editor as FabricJSEditor}
            activeObject={activeObject}
            refreshProjectCard={refreshProjectCard}
            close={handleCloseContextMenu}
          />
          {projectLoading && loadingThumbnails && (
            <Components.EditorCard>
              <CircleLoader2 shineColor="#6620C7" />
            </Components.EditorCard>
          )}
        </Components.CanvasWrapper>
      </Components.OutletWrapper>
      {isShowGoBackModal && goBackModalContent}
      <Components.Footer>
        <ContactSupport />
        {showSaveAlert && (
          <Text color="middleGray" small>
            {t('editor.autoSave')}
          </Text>
        )}
      </Components.Footer>
    </>
  ) : null;
};

import React, {
  ChangeEvent,
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import FabricTypes from 'fabric/fabric-impl';

import ToggleSwitch from '../toggle.component';
import Dropdown from '../dropdown.component';
import { Flex } from '../flexbox.component';
import { GRID_COLOR, GRID_SIZE } from '../../constants';
import {
  enableGridMoving,
  enableGridResizing,
  enableGridResizingTextBox,
} from '../../utils/editor.utils';

const Wrapper = styled.div<{ enableLayout: boolean }>`
  position: absolute;
  bottom: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > ${Flex} {
    gap: 0.5rem;
    & > * {
      &:not(:first-child) {
        opacity: ${(p) => (p.enableLayout ? 1 : 0)};
        visibility: ${(p) => (p.enableLayout ? 'visible' : 'hidden')};
        & > button {
          padding: 0.625rem 0;
        }
      }
    }
  }
`;

const ColorBtn = styled.div<{ active: boolean; color: string }>`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: ${(p) => (p.active ? `2px solid ${p.theme.primary}` : `1px solid ${p.theme.lightGray2}`)};
  background: ${(p) => p.color};
  &:hover {
    cursor: pointer;
    border: ${(p) => `2px solid ${p.theme.primary}`};
  }
`;

interface IProps {
  editor: FabricJSEditor;
  setShowLayout: Dispatch<SetStateAction<boolean>>;
}

export const TopToolbar = forwardRef(function TopToolbar({ editor, setShowLayout }: IProps, ref) {
  const defaultColor = localStorage.getItem('gridColor') || 'rgba(102, 32, 199, 0.1)';
  const [gridColor, setGridColor] = useState(defaultColor);
  const [grid, setGrid] = useState(GRID_SIZE[0].value);
  const [enableLayout, setEnableLayout] = useState(false);
  const [didMount, setDidMount] = useState(false);

  useEffect(() => setDidMount(true), []);

  useImperativeHandle(
    ref,
    () => {
      return {
        reloadGrid: () => {
          if (editor && enableLayout) {
            removeGrid();
            addGrid();
          }
        },
      };
    },
    [enableLayout, editor, grid],
  );

  const addGrid = (gridSize: number = grid) => {
    const { canvas } = editor as FabricJSEditor;
    const measurementThickness = -0.5;
    const height = canvas.height as number;

    const objects = [];
    const linesCount: number = height / gridSize;
    for (let i = 0; i < linesCount; i++) {
      const offset = i * gridSize,
        location1 = offset + measurementThickness;

      const params = { stroke: gridColor }; // #ccc
      if (i !== 0) {
        objects.push(new fabric.Line([location1, measurementThickness, location1, height], params));
        objects.push(new fabric.Line([measurementThickness, location1, height, location1], params));
      }
    }

    const gridLayout = new fabric.Group(objects, {
      name: 'grid',
      selectable: false,
      evented: false,
      hasControls: false,
    });
    canvas.add(gridLayout);
    canvas.bringToFront(gridLayout);
    canvas.on('object:moving', enableGridMoving(gridSize));
    canvas.on('object:scaling', enableGridResizing(gridSize));
    canvas.on('object:resizing', enableGridResizingTextBox(gridSize));
  };

  const removeGrid = () => {
    if (editor) {
      const { canvas } = editor as FabricJSEditor;
      canvas.off('object:moving');
      canvas.off('object:scaling');
      canvas.off('object:resizing');
      const gridObj = canvas._objects.find((obj: FabricTypes.Object) => obj.name === 'grid');
      if (gridObj) {
        canvas.remove(gridObj);
      }
    }
  };

  useEffect(() => {
    setShowLayout(enableLayout);
    if (enableLayout) {
      addGrid();
    } else {
      removeGrid();
    }
  }, [enableLayout]);

  const handleChangeGridSize = (size: number) => {
    setGrid(size);
    if (grid !== size) {
      removeGrid();
      addGrid(size);
    }
  };

  const handleChangeToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setEnableLayout(e.target.checked);
  };

  useEffect(() => {
    if (didMount) {
      addGrid();
      removeGrid();
    }
  }, [gridColor]);

  const handleChooseColor = (color: string) => {
    setGridColor(color);
    localStorage.setItem('gridColor', color);
  };

  return (
    <Wrapper enableLayout={enableLayout}>
      <Flex alignItems="center">
        <ToggleSwitch
          checked={enableLayout}
          onChange={handleChangeToggle}
          label={'editor.layout'}
        />
        <Dropdown
          value={grid}
          handleChange={handleChangeGridSize}
          options={GRID_SIZE}
          design="corner-radius"
        />
        {GRID_COLOR.map((color) => (
          <ColorBtn
            key={color.iconColor}
            color={color.iconColor}
            active={gridColor === color.value}
            onClick={() => handleChooseColor(color.value)}
          ></ColorBtn>
        ))}
      </Flex>
    </Wrapper>
  );
});

import React, { useEffect, useRef, useState } from 'react';
import { FlexCol } from './flexbox.component';
import { Button } from './button.component';
import { IProject } from '../interfaces/projects.interface';
import { useTranslation } from 'react-i18next';
import { FabricJSEditor } from 'fabricjs-react';
import { useUserContext } from '../contexts/user.context';
import { RateNames } from '../constants/rates.constant';
import RateModal from './rates/rate-modal.component';
import { downloadProject } from '../utils/canvaspng';

const DownloadProject: React.FC<{
  exportAsImage: () => void;
  project: IProject;
  editor: FabricJSEditor | undefined;
}> = ({ exportAsImage, project, editor }) => {
  const downloadWrapperRef = useRef<HTMLDivElement | null>(null);
  const { userData } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        editor &&
        downloadWrapperRef.current &&
        !downloadWrapperRef.current.contains(event.target as Node)
      ) {
        setDownloadDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editor, downloadWrapperRef]);

  const [downloadDropdown, setDownloadDropdown] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleExport = () => {
    if (userData) {
      const { rate } = userData;
      if (rate?.name === RateNames.TRIAL) {
        setIsOpen(true);
        return;
      }
    }

    exportAsImage();
  };

  const handleDownloadProject = () => {
    if (userData) {
      const { rate } = userData;
      if (rate?.name === RateNames.TRIAL) {
        setIsOpen(true);
        return;
      }

      if (project.projectItems.length > 1) {
        downloadProject(
          project.projectItems.map((item) => item.details),
          project.projectName,
        );
      } else {
        exportAsImage();
      }
    }
  };

  return (
    <div ref={downloadWrapperRef}>
      <Button
        icon={'download'}
        additionalIcon={'chevron-down'}
        style={{ marginLeft: '10px' }}
        onClick={() => {
          setDownloadDropdown(!downloadDropdown);
        }}
      >
        {t('action.export')}
      </Button>
      {downloadDropdown && (
        <FlexCol
          style={{
            position: 'absolute',
            right: '0',
            top: 'calc(50px + 6px)',
            zIndex: 100,
            alignItems: 'flex-end',
          }}
          gap={6}
        >
          <Button btnStyle="gray" width="fit-content" onClick={() => handleDownloadProject()}>
            Все холсты
          </Button>
          <Button btnStyle="gray" width="fit-content" onClick={handleExport}>
            Только текущий
          </Button>
        </FlexCol>
      )}
      <RateModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export default DownloadProject;

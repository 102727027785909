import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { DEBOUNCE_SAVE } from '../constants/shared.constants';
import { IPopoverActive } from '../interfaces/editor.interface';

type Props = {
  children: React.ReactNode;
};

type TDropped = {
  value: boolean;
  message?: string;
};

export type RecentTemplateIds = {
  ids: number[];
  type: 'templates' | 'infographics' | '';
};

type SharedContextType = {
  fileDropped: TDropped;
  setFileDropped: (value: TDropped) => void;
  isSupportOpen: boolean;
  setIsSupportOpen: (value: boolean) => void;
  recentTemplateIds: RecentTemplateIds;
  setRecentTemplateIds: (value: RecentTemplateIds) => void;
  clearRecentTemplateIds: () => void;
  startSaveTimer: () => void;
  secondsSaveTimer: number;
  stopSaveTimer: () => void;
  isTiming: boolean;
  setIsTiming: (value: boolean) => void;
  popoverActive: IPopoverActive;
  setPopoverActive: React.Dispatch<React.SetStateAction<IPopoverActive>>;
  pageIndexes: { copied: number, current: number };
  handleIndexChange: (key: string, value: number) => void;
};

const SharedContext = createContext<SharedContextType>({
  popoverActive: {},
  setPopoverActive: () => null,
  fileDropped: { value: false, message: '' },
  setFileDropped: () => null,
  isSupportOpen: false,
  setIsSupportOpen: () => null,
  recentTemplateIds: {
    ids: [],
    type: '',
  },
  setRecentTemplateIds: () => null,
  clearRecentTemplateIds: () => null,
  startSaveTimer: () => null,
  secondsSaveTimer: DEBOUNCE_SAVE,
  stopSaveTimer: () => null,
  isTiming: false,
  setIsTiming: () => null,
  pageIndexes: { copied: 0, current: 0 },
  handleIndexChange: () => null,
});

export const SharedProvider: React.FC<Props> = ({ children }) => {
  const [fileDropped, setFileDropped] = useState<TDropped>({ value: false, message: '' });
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false);
  const [recentTemplateIds, setRecentTemplateIds] = useState<RecentTemplateIds>({
    ids: [],
    type: '',
  });
  const [popoverActive, setPopoverActive] = useState<IPopoverActive>({});
  const [seconds, setSeconds] = useState<number>(DEBOUNCE_SAVE);
  const [isTiming, setIsTiming] = useState<boolean>(false);
  const [pageIndexes, setPageIndexes] = useState<{ copied: number, current: number }>({ copied: 0, current: 0 });
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const stopSaveTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setSeconds(DEBOUNCE_SAVE);
  };

  const startSaveTimer = () => {
    stop();
    intervalRef.current = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 0) {
          stop();
          return DEBOUNCE_SAVE;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const clearRecentTemplateIds = () => {
    setRecentTemplateIds({
      ids: [],
      type: '',
    });
  };

  const handleIndexChange = (key: string, value: number) => {
    setPageIndexes((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  const value = useMemo(
    () => ({
      popoverActive,
      setPopoverActive,
      fileDropped,
      setFileDropped,
      isSupportOpen,
      setIsSupportOpen,
      recentTemplateIds,
      setRecentTemplateIds,
      clearRecentTemplateIds,
      startSaveTimer,
      secondsSaveTimer: seconds,
      stopSaveTimer,
      isTiming,
      setIsTiming,
      pageIndexes,
      handleIndexChange
    }),
    [fileDropped, isSupportOpen, recentTemplateIds, seconds, isTiming, popoverActive, pageIndexes.copied, pageIndexes.current],
  );

  return <SharedContext.Provider value={{ ...value }}>{children}</SharedContext.Provider>;
};

export const useShared = (): SharedContextType => {
  return {
    ...useContext(SharedContext),
  };
};
